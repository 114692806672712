import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import SEO from "../components/seo";
import Skel from "../components/skel";

const DigitalPour = () => (
  <Skel>
    <SEO title="Taplist.io is your Digital Pour alternative" />
    <section
      className="section bg-center bg-cover effect-section"
      style={{ paddingTop: "50px", paddingBottom: "50px" }}
    >
      <div className="mask theme-bg opacity-8"></div>
      <Container>
        <Row>
          <Col lg={12} className="text-center">
            <h2 className="white-color h1 m-20px-b">
              Taplist.io is a DigitalPour Alternative
            </h2>
          </Col>
        </Row>
      </Container>
    </section>
    <main>
      <section className="section">
        <Row>
          <Col md={{ span: 8, offset: 2 }}>
            <h3>What is DigitalPour?</h3>
            <p>
              <a target="_blank" rel="noopener noreferrer">
                Digital Pour
              </a>{" "}
              is a beer menu system which can do many of the things Taplist.io
              does. It allows you to put beverages on tap and show them on a TV,
              like a digital chalkboard.
            </p>
            <p>
              If you're trying to decide what digital beer menu system to use
              you may be wondering how we compare. We put this page together to
              summarize the main differences we see and hear about.
            </p>

            <h3>What makes Taplist.io a great alternative to DigitalPour?</h3>
            <p>
              Here are some of the biggest reasons our customers have chosen Taplist.io
              instead of DigitalPour.
            </p>

            <h5>
              <i className="fas fa-check"></i> Transparent, straightforward,
              no-contract pricing
            </h5>
            <p>
              DigitalPour does not publish their prices. One source we've found,
              a public review of DigitalPour, says it can{" "}
              <a href="https://www.cardfellow.com/blog/digital-pour-review/">
                cost $99/month
              </a>
              , which is almost <b>four times the cost</b> of Taplist.io.
            </p>
            <p>
              At Taplist.io, we offer transparent and upfront pricing &mdash;
              it's all right there on <a href="/pricing">our pricing page</a>.
              There are no hidden fees, and no salesman calls. And for peace of
              mind, our <a href="/refund-policy">refund policy</a> guarantees
              that if you get started with us and aren't satisfied, we'll make
              it right.
            </p>

            <h5>
              <i className="fas fa-check"></i> Private, instantly-updating
              beverage database
            </h5>
            <p>
              On DigitalPour, your beverages come from a shared database. If
              your beverage isn't in their system or needs a correction, you
              need to{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.digitalpour.com/add-my-beverage"
              >
                fill out an email form and wait for them
              </a>{" "}
              to make the change. We've heard this can be quite frustrating when
              you need to change your menu <b>now</b>.
            </p>
            <p>
              At Taplist.io, you're in <b>complete control</b> of your beverage
              database: Beverage names, descriptions, and artwork, are all set
              and managed by you &mdash; and{" "}
              <b>updates are published instantly</b>. Many craft breweries
              choose us because of this powerful flexibility: After working so
              hard to produce a brew, who wants to use <em>someone else's</em>{" "}
              description or rating?
            </p>

            <h5>
              <i className="fas fa-check"></i> Cloud management dashboard
            </h5>
            <p>
              DigitalPour is managed through a Windows-only "Ops Program". More
              recently, DigitalPour introduced mobile "Ops App". These two
              applications have overlapping functionality, but are different.
            </p>
            <p>
              Taplist.io is managed entirely through a rich web-based{" "}
              <a href="https://app.taplist.io">management dashboard</a>. This
              universal dashboard works equally well on mobile and desktop. It
              can be used from iOS, Android, Windows, or Mac, with no additional
              software. You can add (and remove) staff accounts so they can
              manage your menu here, too.
            </p>

            <h5>
              <i className="fas fa-check"></i> Clean, customizable designs that
              don't require a designer
            </h5>
            <p>
              DigitalPour has a TV menu design that is fairly rigid. If you see
              a beer board with neon green or bright red kegs and a fixed grid,
              chances are you're looking at a DigitalPour board.
            </p>
            <p>
              With Taplist.io, you can easily achieve a look that is as unique
              as your business. Because Taplist.io is newer, our menu templates
              are clean and highly customizable. You can choose from several
              built-in themes and presets, and customize many more aspects of
              your menu, all without needing to call or consult a designer.
            </p>

            <h5>
              <i className="fas fa-check"></i> Uses low cost, widely-available
              hardware
            </h5>
            <p>
              DigitalPour requires{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://digitalpour.com/docs/hardware-requirements"
              >
                rather specialized hardware
              </a>{" "}
              that may be costly to acquire or difficult to replace. At the time of
              writing, one of their recommended devices was out of stock,
              and another cost over $300.
            </p>
            <p>
              Taplist.io{" "}
              <b>
                works with two of the most popular, widely available hardware
                devices
              </b>{" "}
              on the planet: The Amazon FireTV Stick, and Apple TV. We did this
              so you can <b>easily set up, expand, or replace</b> your menu
              hardware.
            </p>

            <h3>Which is right for me?</h3>
            <p>
              Just like a craft beer, there is plenty of room for different
              approaches to the same basic idea. Over the years, we've found
              some customers may just be better off with a different product.
              Here are some of the unique aspects of DigitalPour that could make
              it a better fit for you.
            </p>
            <ul>
              <li>
                <b>Point-of-sale integration:</b> If you want sophisticated
                integration to your point-of-sale system, DigitalPour might be a
                better choice.
              </li>
              <li>
                <b>Field sales and support:</b> If you prefer a higher touch
                field sales/support operation, DigitalPour might be a better
                choice for you.
              </li>
              <li>
                <b>Advanced reporting:</b> DigitalPour has reporting and
                analytics features beyond what we offer.
              </li>
            </ul>
            <h3>It's Easy To Give Taplist.io a Try</h3>
            <p>
              We know that this is an important decision, and you shouldn't have
              to <em>hope</em> that a product is good before purchasing it.{" "}
              <b>Don't take our word for it:</b> We designed Taplist.io to be
              simple to try before buying.
            </p>

            <p>
              You can create an account, customize your menu, and explore all
              features before buying. Just visit our{" "}
              <a href="/signup">signup page</a> to get started.
            </p>
          </Col>
        </Row>
        <Row>
          <Col md={{ span: 8, offset: 2 }}>
            <hr />
            <em>Last Updated: January 2022</em>
            <br />
            <em>
              Questions, corrections, or need clarifications? Please{" "}
              <a href="mailto:support@taplist.io">contact us</a>!
            </em>
          </Col>
        </Row>
      </section>
    </main>
  </Skel>
);

export default DigitalPour;
